@import "../../variables";

header {
  height: 700px;
}

.header__slider__3 {
  height: 100%;
  overflow: hidden;


  .slider__item {
    height: 100vh;

    .slider__bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .blur__slider__bg {
      height: 700px;

      img {
        filter: blur(15px);
        width: 102%;
        height: 102%;
        margin-left: -1%;
      }
    }

    .slider__desc {
      width: 100%;
      position: absolute;
      top:125px;
      left: 0;
      transform: initial;

      & .wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        a {
          outline: none;
          width: 100%;
        }

        .slider__bg {
          height: 100%;
          height: 455px;
        }

        & .slider3-next, & .slider3-prev {
          font-size: 27px;
          position: absolute;
          z-index: 1000;
          background: rgba(67,92,218,.95);
          width: 40px;
          height: 40px;
          border-radius: 3px;
          color: #fff;
          line-height: 42px;
          text-align: center;
          display: block;
          cursor: pointer;
          transition: all .2s ease;
          opacity: 0.6;

          &:hover {
            opacity: 1;
            background: $blue;
          }
        }
        & .slider3-next {
          right: 30px;
        }
        & .slider3-prev {
          left: 30px;
        }
      }
    }
  }
}



@media (max-width: 1300px) {
  .header__slider__3 .slider__item .slider__desc .wrap .slider__bg {
    height: 378px;
  }
  .header__slider__3 .slider__item .blur__slider__bg, header {
    height: 624px;
  }
}
@media (max-width: 1100px) {
  .header__slider__3 .slider__item .slider__desc .wrap .slider__bg {
    height: 278px;
  }
  .header__slider__3 .slider__item .blur__slider__bg, header {
    height: 524px;
  }
}
@media (max-width: 800px) {
  .header__slider__3 .slider__item .slider__desc .wrap .slider__bg {
    height: 200px;
  }
  .header__slider__3 .slider__item .blur__slider__bg, header {
    height: 446px;
  }
  .header__slider__3 .slider__item .slider__desc .wrap .slider3-next, .header__slider__3 .slider__item .slider__desc .wrap .slider3-prev {
    font-size: 15px;
    width: 26px;
    height: 26px;
    border-radius: 2px;
    line-height: 27px;
  }
}
@media (max-width: 650px) {
  .header__slider__3 .slider__item .blur__slider__bg, header {
    height: 426px;
  }
  .header__slider__3 .slider__item .slider__desc .wrap .slider3-prev {
    left: 0;
  }
  .header__slider__3 .slider__item .slider__desc .wrap .slider3-next {
    right: 0;
  }
}

@media (max-width: 570px) {
  .header__slider__3 .slider__item .slider__desc .wrap .slider__bg {
    height: 150px;
  }
  .header__slider__3 .slider__item .blur__slider__bg, header {
    height: 369px;
  }
}
@media (max-width: 450px) {
  .header__slider__3 .slider__item .slider__desc .wrap .slider__bg {
    height: 100px;
  }
  .header__slider__3 .slider__item .blur__slider__bg, header {
    height: 319px;
  }
}