.form__user {
  padding: 100px 0;

  span.ms__link {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .form__block {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;

    .form__block_dc {
      margin: 20px 50px;
    }

    .form__item__check {
      font-size: 12px;
      font-weight: 500;
      padding: 10px 10px 10px 31px;
    }

    .form__title {
      text-align: center;
      width: 100%;
      background: $wlight;
      height: 74px;
      line-height: 74px;
      position: relative;
      border-bottom: 1px solid $borderc;
      overflow: hidden;

      span {
        z-index: 100;
        position: relative;
      }

      &:after {
        content: '';
        position: absolute;
        right: 46px;
        top: 0px;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        content: "\f007";
        font-size: 110px;
        color: #ededed;
        transform: rotate(-30deg);
      }
    }
  }

  .submit__btn {
    width: 100%;
    border-radius: 0;
    border: 0;
    height: 60px;
    border-top: 1px solid $blue;

    &:hover {
      border-color: $borderc;
    }
  }
}

@media (max-width: 500px) {
  .form__user .form__block {
    border-radius: 0;
  }
  .form__user .form__block .form__block_dc {
    margin: 0;
    border-radius: 0;
    border: 0;
  }
  .form__user .form__block .form__title:after {
    right: -10px;
  }
  .form__user .form__block .form__title {
    margin-bottom: 0;
    height: 60px;
    line-height: 60px;
  }
  .form__user {
    padding: 0;
  }
}