@import "../../variables";

header {
  height: 100vh;

  &.other__pages {
    height: 320px;
    background: $blue;

    .header__top__left .search__btn button:hover {
      opacity: 0.7;
      color: $white;
    }

    .header__top .header__top__mp.menu__tt a:hover .count__header {
      color: $white!important;
    }

    .fixheader .header__top__left .search__btn button:hover {
      opacity: 1;
      color: $blue;
    }

    .header__top.fixheader .header__top__mp.menu__tt a:hover .count__header {
      color: $blue!important;
    }

    .header__top.fixheader  .header__top__right .header__top__mp>a:hover i {
      opacity: 1;
      color: $blue;
    }
    .header__top.fixheader  button.burger.active, header .header__top.fixheader  button.burger:hover {
      color: $blue;
    }
  }

  .header__top {
    background: #fff;
    position: fixed;
    z-index: 1500;
    width: 100%;
    .wrap {
      color: $black;
      display: flex;
      flex-direction: column;
      top:0;
      left: 0;
      align-items: center;
      justify-content: space-between;
      transition: all .5s ease;
    }

    a {
      color: $black;

      :hover {
        color: $blue;
      }
    }



    .header__top__center {
      width: 100%;
      display: flex;
      padding: 10px 0;
      justify-content: space-between;
      align-items: center;

      > a {
        display: flex;

        &:hover {
          opacity: 0.7;
        }
      }

      img {
        width: 160px;
        height: 36px;
        object-fit: contain;
      }
      .header__top__cr {
        display: flex;
        align-items: center;
      }

      .head__sr_btn {
        display: none;
      }

      .search__btn {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: -1;
        display: flex;
        padding: 0 15px;
        height: 100%;
        background: #fff;
        align-items: center;
        transition: all .4s ease;

        &.active {
          z-index: 100;
          opacity: 1;
        }
      }

      .search__btn .search__panel {
        width: 100%;
        position: relative;
        margin-right: 0;

        input {
          width: 100%;
          outline: none;
          padding: 0 50px;
          height: 36px;
          font-size: 14px;
          border: 1px solid #e8e8e8;
        }

        .search__header__btn {
          position: absolute;
          right: 1px;
          top:1px;

          button {
            color: $black;
            font-size: 16px;
            padding: 0;
            text-align: center;
            width: 36px;
            height: 34px;
            line-height: 36px;
            background: #fff;
            outline: none;
            cursor: pointer;
            border: 0;

            &:hover {
              color: $blue;
            }
          }
        }

        .close__search {
          color: $wgrey;
          font-size: 24px;
          -webkit-transition: all .2s ease;
          transition: all .2s ease;
          position: absolute;
          left: 1px;
          text-align: center;
          cursor: pointer;
          top: 1px;
          height: 34px;
          line-height: 36px;
          background: #fff;
          width: 36px;
          border-right: 1px solid #e8e8e8;

          &:hover {
            color: $blue;
          }
        }
      }
    }

    button.burger {
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      color: $white;
      padding: 20px 26px 20px 65px;

      span.box {
        left: 20px;

        span.bar {
          background-color: $white;
        }
      }
    }
    .category__menu {
      width: auto !important;
      background: $blue;
      border-top: 1px solid #172c98;
      margin-top: -1px;

      .wsmenu {
        padding: 0 15px;
        top: 59px;
      }
    }

    .link__menu > {

      .close__link__menu {
        display: none;
      }

      ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0 0 0 20px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;

        li {
          margin: 0 15px;

          a {
            text-decoration: none;
            border-bottom: 2px solid transparent;

            &:hover {
              color: $blue;
              border-color: $blue;
            }
          }

          &.catalog__drop {

            .sub-menu {

              li {
                margin: 0;

                a {
                  border-bottom: 1px solid #f1f1f1;
                }
              }
            }
          }
        }
      }
    }
    button.burger .category__menu__top {
      font-weight: 700;
      display: block;
      text-transform: uppercase;
      font-size: 16px;
    }

    .header__top__left {
      width: 100%;
      padding-right: 180px;

      nav.greedy {
        position: relative;
        height: 60px;
        display: flex;
        width: 100%;
        align-items: center;
      }

      nav.greedy button {
        align-self: stretch;
        transition: all .4s ease-out;
        padding: 0 1rem 0 1.5rem;
        outline: 0;
        border: 0;
        font-size: 0.9rem;
        font-weight: bold;
        background: $blue;
        color: $white;
        cursor: pointer;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
      }

      nav.greedy button.hidden {
        transition: none;
        width: 0;
        padding: 0;
        overflow: hidden;
      }

      nav.greedy button::after {
        content: attr(count);
        display: inline-flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        background: $white;
        color: $blue;
        border-radius: 50%;
        font-size: 14px;
        line-height: 14px;
        margin-left: 1rem;
        margin-right: calc(-1rem + -16px);
      }

      ul.links {
        display: flex;
        flex: 1;
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }

      ul.links li {
        flex: none;
        padding: 1rem;

        &:first-child {
          padding: 1rem 1rem 1rem 0;
        }
      }

      ul.links li a {
        color: $black;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 16px;

        &:hover {
          color: $blue;
        }
      }

      ul.hidden-links {
        position: absolute;
        background: $white;
        right:-106px;
        top: 100%;
        min-width: 190px;
        list-style: none;
        margin: 0;
        padding: 0;
      }

      ul.hidden-links li a {
        text-transform: uppercase;
        font-weight: 700;
        text-align: left;
        display: block;
        border-bottom: 1px solid #f1f1f1;
        line-height: 22px;
        padding: 4px 12px;
        font-size: 11px;
        text-decoration: none;
        letter-spacing: normal;

        &:hover {
          color: $blue;
        }
      }

      ul.hidden-links.hidden {
        display: none;
      }
    }

    .header__top__left, .header__top__right {
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    .header__top__right .link__menu__btn {
      display: none;
    }

    .header__top__right .search__btn button {
      padding: 25px 26px;
    }

    & .header__top__right .search__btn button:hover {
      color: $blue;
    }
    .header__bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .header__top__right {

      .head__sr_btn {
        display: none;
      }

      .user_auth, .catalog__header {
        border-left: 1px solid rgba(255, 255, 255, 0.08);
        position: relative;

        & ul.sub-menu {
          top: 58px;
        }
      }

      .header__top__mp {

        & > a {
          position: relative;
          padding: 14px 16px;
          display: block;

          &:hover i {
            color: $blue;
          }

          i {
            transition: all .2s ease;
          }

          &:hover .count__header {
            text-decoration: none;
            color: #fff;
          }
        }
      }

      .basket {
        border-right: 0;
      }

      .count__header {
        background: #23a6ff;
        color: $white;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 17px;
        font-weight: 600;
        font-size: 10px;
        display: block;
        position: absolute;
        top: 7px;
        right: 4px;
      }
    }
  }
}

@media (max-width: 1500px) {
  header .header__top .header__bottom.wrap {
    padding-left: 0;
  }

  header .header__top .category__menu .wsmenu {
    padding: 0;
  }
}
@media (max-width: 1380px) {
  header .header__top .link__menu ul li {
    margin: 0 10px;
  }
  header .header__top .header__top__right .search__btn .search__panel {
    width: 260px;
    margin-right: 10px;
  }
}

@media (max-width: 1200px) {
  header .header__top .header__top__right .head__sr_btn {
    display: block;
  }
  header .header__top .header__top__right .search__btn button {
    padding: 14px 16px;
    background: #fff;
    border: 0;
    outline: none;
  }
  header .header__top .header__top__right .search__btn .search__panel {
    position: absolute;
    top: 22px;
    opacity: 0;
    z-index: -1;
    transition: 0.5s cubic-bezier(0.86, 0, 0.07, 1);

    &.active {
      opacity: 1;
      top:58px;
      z-index: 10;
    }
  }
}
@media (max-width: 991px) {
  header .header__top .header__top__right .link__menu__btn {
    display: block;
    padding: 14px 16px;
    font-size: 19px;
  }

  .catalog__drop ul.sub-menu {
    display: none !important;
  }

  header .header__top .link__menu .close__link__menu {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 15px;
    font-size: 14px;
    color: $red;

    i {
      font-size: 35px;
      color: $black;
    }
  }

  .link__menu {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: rgba(255, 255, 255, 0.95);
    width: 100%;
    height: 100%;
    display: block;
    z-index: 5000;
    transition: 0.7s cubic-bezier(0.86, 0, 0.07, 1);

    &.active {
      right: 0;
      display: block;
    }
  }
  header .header__top .link__menu ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    align-items: center;
    height: 100%;
    font-size: 20px;
    justify-content: center;

    & li {
      margin: 15px 0;

      a {
        display: block;

        &:hover {
          border-color: $white;
        }
      }
    }
  }
  .wsmenu {
    top: 0 !important;
    padding-top: 0 !important;
    z-index: 2000;
  }
}

@media (max-width: 650px) {
  header .header__top .category__menu {
    border-top: 0;
    margin-top: -1px;
    position: absolute;
    top: 60px;
    left: 0;
    display: flex;
    width: 100%!important;
    justify-content: center;
  }
  header .header__top .header__bottom {
    justify-content: center;
  }
  header .header__top button.burger {
    padding: 15px 26px 15px 65px;
  }
  header .header__top .header__top__center {
    position: relative;
    top:0;
    padding:10px 0 0 0;
    flex-direction: column;
  }
  header .header__top.fixheader {
    top:0;
  }
  header .header__top .header__top__center .search__btn {
    width: 111%;
    left: -15px;
  }
  header .header__top .header__top__center img {
    width: 120px;
    height: 30px;
  }
  header .header__top .header__top__right .search__btn .search__panel {
    width: 100%;
    left: 0;
  }
  header .header__top .header__top__right .search__btn .search__panel.active {
    z-index: 10000;
  }
  header .header__top .header__top__right .search__btn .search__panel input {
    height: 49px;
  }
  header .header__top .header__top__right .search__btn .search__panel .search__header__btn {
    top: 8px;
  }
}

