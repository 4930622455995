@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=cyrillic');
/**
 * Основные стили
 **/

* {
	box-sizing: border-box;
}

html {
	font-size: 15px;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 14px;
	overflow-wrap: break-word;
	word-wrap: break-word;
	line-height: 1.667;

	&.over__hide {
		overflow: hidden;
	}
}

table {
	width: 100%;
	border-collapse: collapse;
	background: $white;
}

/* Zebra striping */
tr:nth-of-type(odd) {
	background: $wlight;
}

th {
	background: $blue;
	color: white;
	font-weight: bold;
}

td, th {
	padding: 10px;
	border: 1px solid $borderc;
	text-align: left;
}

ul.list__st	{
	padding: 20px 0 20px 13px;
	margin: 0;
	list-style: none;

	li {
		position: relative;

		&:after {
			content: "•";
			position: absolute;
			left: -13px;
			top: -12px;
			color: #435cda;
			font-size: 25px;
		}
	}
}

.form__block {
	border-radius: 15px;
	padding: 30px;
	width: 100%;
	max-width: 700px;
	margin-top: 40px;
	background: $white;
	box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.1);

	&.nostyle {
		border-radius: 0;
		padding: 0;
		width: 100%;
		max-width: initial;
		margin-top: 0;
		background: transparent;
		box-shadow: none;
	}

	.form__title {
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 15px;
	}

	.form__block_dc {
		border-radius: 15px;
		border: 1px solid $borderc;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
	}

	.form__item {
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid $borderc;
		padding: 0 30px 0;
		position: relative;
		width: 100%;


		&.block50 {
			width: 50%;

			&:nth-child(2n) {
				border-left: 1px solid $borderc;
			}
		}

		&:last-child {
			border-bottom: 0;
		}


		input {
			border: 0;
			height: 40px;
			background: 0;
			outline: none;
			margin-top: -9px;
		}

		textarea {
			border: 0;
			height: 120px;
			background: 0;
			outline: none;
			margin-top: 0;
		}

		label {
			transition: 0.2s cubic-bezier(0.86, 0, 0.07, 1);
			transform: translateY(15px);
			pointer-events: none;
			font-size: 15px;
			color: $wgrey;
			height: 25px;
		}
		.moveUp {
			transform: translateY(10px);
			font-size: 10px;
		}

		img.captcha__b {
			position: absolute;
			right: 0;
			height: 100%;
			border-left: 1px solid $borderc;
		}
	}
}

.content__block {
	min-height: 374px;
}

.catalog__header {
	padding-top: 120px;
	color: #fff;
	text-align: center;

	h1 {
		margin: 0;
		font-size: 26px;
		font-weight:500;
		text-transform: uppercase;
	}

	#path {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		flex-wrap: wrap;

		a {
			color: #fff;
			text-decoration: none;
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}
		}

		i {
			opacity: 0.5;
			margin: 0 7px;
		}
	}
}

.autocomplete-suggestions {
	background: #fff;
	overflow: auto;
	padding: 10px 0;
	border-top: 1px solid $borderc;
	box-shadow: 0 13px 10px rgba(0, 0, 0, 0.2);
}

.autocomplete-suggestions .autocomplete-suggestion {
	font-size: 12px;
	border-bottom: 1px solid $borderc;
	padding: 5px 20px;
	cursor: pointer;
}

.submit__btn {
	background: $blue;
	border: 1px solid $blue;
	color: #fff;
	font-size: 14px;
	margin-top: 30px;
	text-transform: uppercase;
	font-weight: 600;
	padding: 0 30px;
	display: flex;
	display: inline-block;
	text-decoration: none;
	line-height: 50px;
	align-items: center;
	border-radius: 50px;
	outline: 0;
	height: 50px;
	cursor: pointer;
	box-shadow: 0 5px 19px rgba(77,100,220,.5);
	transition: all .2s ease;
	border: 1px solid $blue;

	&:hover {
		border-color: $borderc;
		background: $white;
		color: $blue;
		box-shadow: 0 5px 19px rgba(77, 100, 220, 0);
	}

	&.small_btn {
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 500;
		border: 1px solid $blue !important;
		padding: 0 30px;
		text-align: center;
		line-height: 35px;
		height: 35px;
		box-shadow: 0 2px 5px rgba(77,100,220,.5);

		&:hover {
			border: 1px solid $borderc !important;
			background: $white;
			color: $blue;
			box-shadow: 0 2px 5px rgba(77, 100, 220, 0);
		}
	}
}

.form__title {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	padding: 0 20px;
}

.submit__btn__green {
	background: #6db42e;
	box-shadow: 0 5px 19px rgba(109, 180, 46, 0.5);
	border: 1px solid #6db42e;
	margin-bottom: 30px;

	&:hover {
		color: #6db42e;
		border-color: $borderc;
		background: #fff;
		box-shadow: none;
	}
}

button.burger {
	display: block;
	position: relative;
	outline: none;
	background-color: transparent;
	border: none;
	appearance: none;
	color: #fff;
	z-index: 1600;
	transition: all .3s;
	cursor: pointer;
}

button.burger span.box {
	display: block;
	position: absolute;
	width: 24px;
	height: 26px;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	transition: .3s;
}

button.burger span.box span.bar {
	right: 0;
	width: 100%;
	height: 3px;
	background-color: $white;
	position: absolute;
	transition: .3s;
	border-radius: 3px;
}

button.burger span.box span.bar.top {
	top: 5px;
}

button.burger span.box span.bar.middle {
	top: 50%;
	margin: -1px 0 0;
}

button.burger span.box span.bar.bottom {
	bottom: 4px;
}

button.burger.active span.box span.bar {
	width: 100% !important;
	background-color: $white;
}

button.burger.active span.box span.bar.top {
	-webkit-transform: rotate(45deg) translate(8px, 7px);
	-ms-transform: rotate(45deg) translate(8px, 7px);
	transform: rotate(45deg) translate(8px, 7px);
	top: 0;
}

button.burger.active span.box span.bar.middle {
	opacity: 0;
}

button.burger.active span.box span.bar.bottom {
	-webkit-transform: rotate(-45deg) translate(8px, -7px);
	-ms-transform: rotate(-45deg) translate(8px, -7px);
	transform: rotate(-45deg) translate(8px, -7px);
	bottom: 2px;
}

.text-center {
	text-align: center;
}

.error__mes {
	padding: 10px 0;
	background: #e02f2f;
	color: #fff;
}

.btn {
	font-size: 15px;
	font-weight: 600;
	border:2px solid rgb(255, 255, 255);
	background: rgba(255, 255, 255, 0);
	border-radius: 50px;
	padding: 15px 60px;
	color: #fff;
	text-decoration: none;
	transition: all .4s ease;

	&:hover {
	  background: darken($blue, 5);
      box-shadow: none;
	}
}
.section__title {
	font-family: 'Play', sans-serif;
	font-size: 30px;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
}

h1  {
  font-family: 'Play', sans-serif;
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
}

h2 {
	font-family: 'Play', sans-serif;
	padding: 0;
	font-weight: 700;
	font-size: 32px;
	line-height: 40px;
	margin: 0;

	&.text-center {
		text-align: center;
	}
}

a {

	&:hover {
		text-decoration: none;
	}
}
a, button {
	transition: all .2s ease;
}

.radio__btn .radio {
	position: absolute;
	z-index: -1;
	opacity: 0;
	margin: 10px 0 0 7px;
}
.radio__btn .radio + label {
	position: relative;
	padding: 5px 10px;
	display: block;
	font-size: 12px;
	margin-right: 10px;
	cursor: pointer;
	transition: all .2s ease;
}
.radio__btn .radio + label {
	border: 1px solid $borderc;
	background: #FFF;
}
.radio__btn .radio + label:hover {
	background: $wlight;
	border-color: $blue;
}
.radio__btn .radio:checked + label {
	background: $blue;
	border-color: $blue;
	color: #fff;
	box-shadow: 0 0 9px rgba(77,100,220,.5);
}


.radio__item .radio {
	position: absolute;
	z-index: -1;
	opacity: 0;
	margin: 10px 0 0 7px;
}
.radio__item .radio + label {
	position: relative;
	padding: 0 0 0 25px;
	cursor: pointer;
}
.radio__item .radio + label:before {
	content: '';
	position: absolute;
	top: 2px;
	left: 0;
	width: 14px;
	height: 14px;
	border: 1px solid $borderc;
	border-radius: 50%;
	background: #FFF;
	transition: .2s;
}
.radio__item .radio + label:after {
	content: '';
	position: absolute;
	top: 7px;
	left: 5px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: $white;
	opacity: 0;
	transition: .2s;
}
.radio__item .radio:checked + label:after {
	opacity: 1;
}
.radio__item .radio + label:hover:before {
	border-color: $blue;
}
.radio__item .radio:checked + label:before {
	border-color: $blue;
	background: $blue;
	box-shadow: 0 2px 2px rgba(77,100,220,.5);
}

.checkbox input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	margin: 10px 0 0 20px;
}
.checkbox__text {
	position: relative;
	padding: 0 0 0 30px;
	cursor: pointer;
}
.checkbox__text:before {
	content: '';
	position: absolute;
	top: 5px;
	left: 0;
	width: 16px;
	height: 16px;
	border: 1px solid $borderc;
	background: $white;
	transition: .2s;
}
.checkbox__text:after {
	content: '';
	position: absolute;
	top: 11px;
	left: 6px;
	width: 5px;
	height: 5px;
	border-radius: 10px;
	background: #FFF;
	transition: .2s;
}
.sidebar__item__desc .checkbox__text:after {
	top: 1px;
	left: 4px;
	font-family: "Font Awesome 5 Pro";
	font-weight: 400;
	content: "\f00c";
	color: #fff;
	width: auto;
	height: auto;
	background: 0 0;
	font-size: 10px;
	margin-right: 10px;
	margin-top: 1px;
}

.sidebar__item__desc .checkbox__text:before {
	top: 1px;
	left: 0;
	border-radius: 3px;
}
.sidebar__item__desc label {
	width: 100%;

	.checkbox__text {
		display: flex;
		justify-content: space-between;
	}
}
.checkbox input:checked + .checkbox__text:before {
	background: $blue;
	border-color: $blue;
	box-shadow: 0 2px 2px rgba(77,100,220,.5);
}

.iziModal .iziModal-header {
	background: $blue !important;
}

/*filter_ajax_p_f*/
.ajax_filter_loader {
	background: url(../images/ajax-loader.gif) no-repeat;
}
/*/filter_ajax_p_f*/

.container {
	@include min-max(320px, 100%, 0);
	overflow: hidden;
}

.wrap {
	@include min-max(320px, 1500px, 15px);
	position: relative;
}

.change_color {
	position:fixed;
	top:40%;
	right:0;
	z-index:1000;
	right:-250px;
	transition: .3s;
}

.change_color.active {
	right:0px;
}

.change_color .change_color_block{
	width:250px;
	background:#fff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.change_color span.change_color_btn {
	position: absolute;
	left: -64px;
	cursor:pointer;
	box-shadow: -4px 2px 4px rgba(0, 0, 0, 0.15);
}

.change_color .savecolors {
	width: 100%;
	background: #3ea0ff;
	border: 0;
	text-transform: uppercase;
	color: #fff;
	padding: 10px 0;
}

@media (max-width: 1200px) {

}

@media (max-width: 1100px) {
	.form__block {
		max-width: 100%;
	}
}

@media (max-width: 991px) {
	.wrap.mobi__wrap {
		padding: 0;
	}
}

@media (max-width: 810px) {

}

@media (max-width: 768px) {
	.form__block .form__item.block50 {
		width: 100%;
	}
	.form__block .form__item.block50:nth-child(2n) {
		border-left: 0;
	}
	.catalog__header h1 {
		font-size: 20px !important;
		line-height: 28px;
	}
	.submit__btn {
		font-size: 12px;
		line-height: 44px;
		height: 44px;
		box-shadow: 0 3px 5px rgba(77,100,220,.5);

		&.submit__btn__green {
			box-shadow: 0 3px 5px rgba(109,180,46,.5);
		}
	}
}

@media (max-width: 610px) {
	.btn {
		font-size: 13px;
		padding: 10px 50px;
	}
}

@media (max-width: 480px) {
	.catalog__header {
		padding-top: 128px;
	}
	.catalog__header h1 {
		font-size: 16px!important;
		line-height: 21px;
	}
	.form__block {
		border-radius: 0;
		padding: 0;
		background: transparent;
		box-shadow: none;
	}
	.form__block .form__block_dc {
		background: #fff;
	}
}

.product__add__rg {
	display: flex;
	align-items: center;

	.product__add__cart {
		background: #fff;
		border: 1px solid $borderc;
		border-radius: 50px;
		outline: 0;
		height: 50px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		color: #000;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 700;
		margin-right: 20px;

		input {
			width: 40px;
			text-align: center;
			font-size: 14px;
			height: 100%;
			color: #767676;
			background: #fff;
			border: 0;
		}

		i {
			font-weight: 300;
			font-size: 16px;
			color: #767676;
			position: relative;
			top:0;
			margin: 0 5px;
			cursor: pointer;

			&:hover {
				color: $blue;
			}
			&:active {
				top:1px;
			}
		}

		span {
			display: flex;
		}
	}
	.button__in__cart {
		background: $blue;
		border: 1px solid $blue;
		color: $white;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 600;
		padding: 0 30px;
		display: flex;
		align-items: center;
		border-radius: 50px;
		outline: 0;
		height: 50px;
		cursor: pointer;
		box-shadow: 0 5px 19px rgba(77, 100, 220, 0.50);

		i {
			font-size: 22px;
			margin-right: 12px;
			font-weight: 300;
		}

		&:hover {
			border-color: $borderc;
			background: $white;
			color: $blue;
			box-shadow: 0 5px 19px rgba(77, 100, 220, 0);
		}
	}
}



@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	table {
		width: 100%;
	}

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr {
		display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	tr { border: 1px solid $borderc; border-bottom: 0;
		margin-top: -1px; }

	td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid $borderc;
		position: relative;
		padding-left: 15px;
	}

	td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		/* Label the data */
		content: attr(data-column);

		color: #000;
		font-weight: bold;
	}

}