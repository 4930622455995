@import "../../variables";
.catalog__sale {
  display: flex;
  justify-content: space-between;

  .catalog__sale__left {
    background: radial-gradient(at 70% 100%, rgba(140,203,255,1) 0%, rgba(60,147,218,1) 70%);
    position: relative;
    width: 66%;
    height: 370px;
    overflow: hidden;
  }

  .catalog__sale__right {
    background: radial-gradient(at 70% 100%, rgba(144,128,255,1) 0%, rgba(92,74,220,1) 70%);
    position: relative;
    width: 34%;
    height: 370px;
    overflow: hidden;

    .sale__block__b {
      padding: 20px 30px 0 40px;
      .sale__block__img {
        width: 210px;
        position: absolute;
        right: 20px;
        bottom: -80px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .sale__block__title {
        font-size: 24px;
        font-weight: 600;
        margin: 38px 0 18px 0;
        width: 100%;
      }
    }
  }
  .sale__block__b {
    position: absolute;
    top:0;
    left: 0;
    padding: 20px 0 0 80px;
    width: 100%;
    height: 100%;
    color: $white;

    .sale__block__img {
      width: 430px;
      position: absolute;
      right: 60px;
      bottom: -80px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .sale__block__badge {
      background: #000;
      color: #fff;
      display: inline-block;
      padding: 3px 10px;
      font-size: 10px;
      font-weight: 600;
    }
    .sale__block__title {
      font-size: 24px;
      font-weight: 600;
      margin: 38px 0 18px 0;
      width: 40%;
    }
    .sale__block__price {
      font-size: 38px;
      font-weight: 700;
      line-height: 38px;
      margin-bottom: 38px;
    }

    a.btn__sale {
      background: rgba(255, 255, 255, 0.07);
      color: #fff;
      text-decoration: none;
      padding: 12px 50px;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      border-radius: 50px;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.13),inset 0px 1px 0px 0px rgba(255, 255, 255, 0.14);

      &:hover {
        background: rgba(255, 255, 255, 0);
      }
    }
  }
}

.filter__mob {
  display: none;
  width: 100%;
  height: 50px;
  background: $red;
  color: $white;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;

  i {
    margin-right: 15px;
  }
}

.catalog__block {
  display: flex;
  justify-content: space-between;

  .catalog__sidebar {
    width: 25%;
    border-right: 1px solid $borderc;
    font-size: 12px;

    .sidebar__head {
      border-bottom: 1px solid $borderc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      padding-left: 60px;

      .close__filter {
        display: none;
      }

      .refresh__filter {
        font-size: 20px;

        button {
          background: transparent;
          border: 0;
          outline: none;
          cursor: pointer;
          color: $blue;
          transition: all .4s ease;
          border-left: 1px solid $borderc;
          height: 74px;
          width: 74px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    .sidebar__item {
      padding: 32px 60px;
      border-bottom: 1px solid $borderc;

      &.filter__price {
        background: $wlight;
        height: 210px;

        #slider-range {
          margin-bottom: 20px;
        }

        .ui-slider {
          position: relative;
          text-align: left;
          background: $borderc;
        }
        .ui-slider .ui-slider-handle {
          position: absolute;
          z-index: 2;
          width: 20px;
          height: 20px;
          background: #fff;
          border-radius: 100%;
          outline: none;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.18);
          cursor: default;
          -ms-touch-action: none;
          touch-action: none;
        }
        .ui-slider .ui-slider-range {
          position: absolute;
          z-index: 1;
          font-size: .7em;
          display: block;
          border: 0;
          background-position: 0 0;
        }

        /* support: IE8 - See #6727 */
        .ui-slider.ui-state-disabled .ui-slider-handle,
        .ui-slider.ui-state-disabled .ui-slider-range {
          filter: inherit;
        }

        .ui-slider-horizontal {
          height: 5px;
          border-radius: 50px;
        }
        .ui-slider-horizontal .ui-slider-handle {
          top: -8px;
          margin-left: -.6em;
        }
        .ui-slider-horizontal .ui-slider-range {
          top: 0;
          height: 100%;
          background: $blue;
          border-radius: 50px;
        }
        .ui-slider-horizontal .ui-slider-range-min {
          left: 0;
        }
        .ui-slider-horizontal .ui-slider-range-max {
          right: 0;
        }

        .ui-slider-vertical {
          width: .8em;
          height: 100px;
        }
        .ui-slider-vertical .ui-slider-handle {
          left: -.3em;
          margin-left: 0;
          margin-bottom: -.6em;
        }
        .ui-slider-vertical .ui-slider-range {
          left: 0;
          width: 100%;
        }
        .ui-slider-vertical .ui-slider-range-min {
          bottom: 0;
        }
        .ui-slider-vertical .ui-slider-range-max {
          top: 0;
        }

        p.price-filters {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;

          input {
            padding: 12px 20px;
            border:1px solid $borderc;
            display: inline-block;
            margin: 0 10px;
            width: 100%;
            font-weight: 500;
            text-transform: uppercase;
            color: $wgrey;
            font-size: 12px;
            border-radius: 50px;
          }
        }
      }

      .sidebar__item__head {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        margin-bottom: 24px;

        &:before {
          position: absolute;
          left: -24px;
          top: -4px;
          font-family: "Font Awesome 5 Pro";
          font-weight: 400;
          content: "\f107";
          font-size: 16px;
        }
      }
      .sidebar__item__desc {

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            justify-content: space-between;
            padding-bottom: 7px;

            .count {
              color: $wgrey;
            }

            a {
              color: #000;
              font-weight: 500;
              text-decoration: none;
              border-bottom: 1px solid transparent;

              &:hover {
                border-color: $blue;
                color: $blue;
              }
            }
          }
        }
      }
    }
  }
  .catalog__block__right {
    width: 75%;
    overflow: hidden;

    .catalog__sort__line {
      width: 100%;
      border-bottom: 1px solid $borderc;
      display: flex;
      justify-content: space-between;

      .catalog__sort__item  {
        height: 74px;
        width: 33.333%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid $borderc;
        padding: 0 30px;

        form {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
        }

        &:last-child {
          border: 0;
          justify-content: flex-end;
          padding-right: 0;

          & .grid__btn, & .list__btn {
            border-left: 1px solid $borderc;
            width: 74px;
            height: 74px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all .4s ease;

            &:hover {
              opacity: 0.7;
            }
            &.active {
              background: $wlight;
            }
          }
        }

        label {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 600;
          margin-right: 30px;
        }

        select {
          border: 0;
          font-size: 12px;
          font-weight: 600;
          padding: 0;
          height: 34px;
          outline: none;
          width: 50%;
        }
      }
    }
    .catalog__block__product {
      display: flex;
      flex-wrap: wrap;


      .catalog__product__item.grid {
        position: relative;
        width: 33.333%;
        border-right: 1px solid $borderc;
        border-bottom: 1px solid $borderc;
        padding: 20px 40px 10px;
        outline: none;

        .after__btn {
          z-index: -100;
          opacity: 0;
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          margin-top: -23px;
          margin-left: -180px;
          padding: 0 40px;
          transition: all .2s ease;
          display: flex;
          align-items: center;


          .button__in__cart {
            background: $white;
            border-radius: 50px;
            border: 0;
            outline: none;
            padding: 12px 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #000;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            margin-right: 20px;

            i {
              margin-right: 10px;
              font-size: 21px;
            }

            &:hover {
              color: $blue;
            }
          }

          & .addcompare, & .addwishlist {
            background: rgba(255, 255, 255, 0.07);
            color: #fff;
            text-decoration: none;
            width: 44px;
            height: 44px;
            flex: 0 0 44px;
            border: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 21px;
            margin: 0 5px;
            border-radius: 50px;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.13),inset 0px 1px 0px 0px rgba(255, 255, 255, 0.14);

            i {
              font-weight: 400;
            }

            &:hover {
              background: rgba(255, 255, 255, 0);
            }
          }
        }

        &:before {
          transition: all .2s ease;
          position: absolute;
          left: 0;
          top:0;
          content: '';
          background: #fff;
          width: 100%;
          opacity: 0;
          height: 100%;
        }

        &:hover:before {
          position: absolute;
          left: 0;
          opacity: 1;
          top:0;
          content: '';
          background: rgba(67, 92, 218, 0.95);
          box-shadow: 0 0 29px rgba(77, 100, 220, 0.50);
          width: 100%;
          height: 100%;
        }

        &:hover {

          a.carousel__title__home {
            color: #fff;
          }

          .carousel__price {
            color: #fff;
          }

          .after__btn {
            z-index: 100;
            opacity: 1;
          }
        }

        & * {
          outline: none;
          display: block;
        }

        img {
          width: 212px;
          height: 212px;
          margin: 0 auto 25px;
          object-fit: contain;
        }
        a.carousel__title__home {
          font-size: 14px;
          color: #000;
          text-decoration: none;
          font-weight: 500;
          display: block;
          position: relative;

          &:hover {
            color: #fff;
          }
        }

        .carousel__price {
          color: $blue;
          font-weight: 800;
          font-size: 18px;
          margin-top: 30px;
          position: relative;
          transition: all .2s ease;
        }

        .old-price {
          color: $wgrey;
          font-weight: 500;
          font-size: 14px;
          position: relative;
          transition: all .2s ease;
        }
      }

      .catalog__product__item.list {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 30px;
        border-bottom: 1px solid $borderc;


        .after__btn {
          padding: 0 20px;
          display: flex;
          flex-direction: row-reverse;
          order: 1;
          align-items: center;


          .button__in__cart {
            background: $white;
            border-radius: 50px;
            outline: none;
            border: 1px solid $borderc;
            padding: 12px 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #000;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;

            i {
              margin-right: 10px;
              font-size: 15px;
            }

            &:hover {
              color: $blue;
              border-color: $blue;
            }
          }

          & .addcompare, & .addwishlist {
            background: $white;
            color: #000;
            text-decoration: none;
            width: 44px;
            height: 44px;
            border: 1px solid $borderc;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            margin: 0 5px;
            border-radius: 50px;

            i {
              font-weight: 400;
            }

            &:hover {
              color: $blue;
              border-color: $blue;
            }
          }
        }

        & * {
          outline: none;
          display: block;
        }

        img {
          width: 80px;
          height: 80px;
          margin: 0 auto;
          object-fit: contain;
        }
        a.carousel__title__home {
          font-size: 14px;
          color: #000;
          flex: 1 1 40%;
          text-decoration: none;
          font-weight: 500;
          padding: 0 30px;
          display: block;
          position: relative;

          &:hover {
            color: $blue;
          }
        }

        .carousel__price {
          color: $blue;
          font-weight: 600;
          font-size: 14px;
          display: flex;
          position: relative;
          transition: all .2s ease;
        }

        .old-price {
          color: $wgrey;
          font-weight: 500;
          font-size: 12px;
          margin-left: 5px;
          position: relative;
          transition: all .2s ease;
        }
      }
    }
    .catalog__pagination {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $borderc;
      border-bottom: 1px solid $borderc;
      margin-top: -1px;

      & > div {
        width: 33.333%;

        a {
          width: 74px;
          height: 74px;
          color: #000;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
        }
      }


      .prev_page_link {
        border-right: 1px solid $borderc;

        a {
          border-right: 1px solid $borderc;
          font-size: 26px;
          color: $wgrey;

          &:hover {
            background: $blue;
            border-right: 1px solid $blue;
            color: #fff;
          }
        }
      }

      .next_page_link {
        display: flex;
        justify-content: flex-end;
        width: 33.34%;

        a {
          border-left: 1px solid $borderc;
          font-size: 26px;
          color: $wgrey;

          &:hover {
            background: $blue;
            border-left: 1px solid $blue;
            color: #fff;
          }
        }
      }

      .center__pages__link {
        display: flex;
        justify-content: center;

        a {
          border-right: 1px solid $borderc;

          &:hover {
            background: $blue;
            border-right: 1px solid $blue;
            color: #fff;
          }

          &.selected {
            background: $blue;
            border-right: 1px solid $blue;
            color: #fff;
            box-shadow: 0 0 29px rgba(77, 100, 220, 0.50);
          }
        }
      }
    }
  }
}



@media (max-width: 1350px) {
  .catalog__block .catalog__block__right .catalog__sort__line .catalog__sort__item {
    padding: 0 15px;
  }
}

@media (max-width: 1200px) {
  .catalog__sale .catalog__sale__left, .catalog__sale .catalog__sale__right {
    width: 50%;
  }
  .catalog__sale .catalog__sale__left .sale__block__b {
    padding: 20px 30px 0 40px;
  }
  .catalog__sale .sale__block__b .sale__block__title {
    width: 100%;
  }
  .catalog__sale .sale__block__b .sale__block__img {
    width: 260px;
    right: 20px;
  }
}

@media (max-width: 991px) {
  .catalog__sale {
    display: none;
  }

  .catalog__block .catalog__sidebar {
    width: 320px;
    height: 100%;
    position: fixed;
    z-index: 4500;
    background: #fff;
    overflow-y: auto;
    left: -320px;
    top:0;
    transition: 0.7s cubic-bezier(0.86, 0, 0.07, 1);

    &.active {
      left: 0;
    }
  }
  .filter__mob {
    display: flex;

    &.fixfilter {
      position: fixed;
      top:51px;
      z-index: 2000;
      width: 100%;
      left: 0;
    }
  }

  .catalog__block .catalog__sidebar .sidebar__head {
    position: fixed;
    top:0;
    left: -320px;
    z-index: 1000;
    transition: .7s cubic-bezier(.86,0,.07,1);
    width: 320px;
    background: rgba(255, 255, 255, 0.9);

    &.active {
      left: 0;
    }
  }

  .catalog__block .catalog__sidebar .sidebar__head .refresh__filter button {
    font-size: 16px;
    height: 58px;
    width: 58px;
  }

  .catalog__block .catalog__sidebar {
    padding-top: 58px;
  }

  .catalog__block .catalog__block__right {
    width: 100%;
  }

  .catalog__block .catalog__block__right .catalog__block__product .catalog__product__item.grid {
    padding: 20px;
  }

  .catalog__block .catalog__block__right .catalog__sort__line .catalog__sort__item:last-child {
    display: none;
  }

  .catalog__block .catalog__block__right .catalog__block__product .catalog__product__item.grid, .catalog__block .catalog__block__right .catalog__sort__line .catalog__sort__item {
    width: 50%;
  }

  .catalog__block .catalog__block__right .catalog__pagination .center__pages__link {
    flex: 0 1 100%;
    justify-content: center;
  }

  .catalog__block .catalog__sidebar .sidebar__head {
    padding-left: 0;
  }
  .catalog__block .catalog__sidebar .sidebar__head .close__filter {
    font-size: 20px;
    display: block;

    button {
      background: transparent;
      border: 0;
      outline: none;
      cursor: pointer;
      color: $blue;
      transition: all .4s ease;
      border-right: 1px solid $borderc;
      width: 58px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@media (max-width: 690px) {
  .catalog__header {
    padding-top: 148px;
    color: #fff;
    text-align: center;
  }
  .catalog__block .catalog__block__right .catalog__sort__line .catalog__sort__item  form {
    flex-direction: column;
    align-items: baseline;
  }
  .catalog__block .catalog__block__right .catalog__sort__line .catalog__sort__item label {
    font-size: 10px;
    color: $wgrey;
  }
  .catalog__block .catalog__block__right .catalog__sort__line .catalog__sort__item select {
    width: 100%;
  }
  .catalog__block .catalog__block__right .catalog__block__product .catalog__product__item.grid {
    width: 100%;
    border-right: 0;
  }
  .catalog__block .catalog__block__right .catalog__block__product .catalog__product__item.grid img {
    width: 150px;
    height: 150px;
  }
  .catalog__block .catalog__block__right .catalog__pagination .prev_page_link, .catalog__block .catalog__block__right .catalog__pagination .next_page_link {
    display: none;
  }
  .catalog__block .catalog__block__right .catalog__pagination>div a {
    width: 58px;
    height: 58px;
  }
}