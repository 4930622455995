.carousel__product__home {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $borderc;

  &:nth-child(2n) {
    flex-direction: row-reverse;
    border-right: 0;

    .carousel__home__block {
      margin-left: -1px;

      .carousel__home__item:before {
        left: 1px;
      }
      .carousel__home__item:hover:before {
        left: 1px;
      }
    }
  }

  .carousel__block__info {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: $black;
    padding:70px 30px;
    text-align: center;
    color: $white;
    border-right: 1px solid $borderc;

    &:last-child {
      border-right: 0;
    }

    i {
      color: $blue;
      font-size: 48px;
    }
    .carousel__block__title {
      font-size: 16px;
      font-weight: 500;
      margin-top: 25px;
      text-transform: uppercase;
    }
    p {
      font-size: 12px;
      color: $wgrey;
      padding:0 30px;
    }
  }
  .carousel__home__block {
    width: 75%;

    .slick-track {
      display: flex;
    }

    .slick-slide {
      height: auto;
    }

    .slick-next {
      right: 6px;
    }

  .slick-prev {
    left: -30px;
  }

    .slick-next:before, .slick-prev:before {
      color: #232323;
      background: #fff;
      border-radius: 100%;
      width: 54px;
      height: 54px;
      line-height: 54px;
      font-size: 38px;
      opacity: 1;
      display: block;
    }

    .carousel__home__item {
      position: relative;
      border-right: 1px solid $borderc;
      padding: 20px 40px 10px;
      outline: none;

      .after__btn {
        z-index: -100;
        opacity: 0;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: -23px;
        margin-left: 0;
        justify-content: center;
        padding: 0 10px;
        transition: all .2s ease;
        display: flex;
        align-items: center;


        .button__in__cart {
          background: $white;
          border-radius: 50px;
          border: 0;
          outline: none;
          padding: 12px 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #000;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          margin-right: 10px;

          i {
            margin-right: 10px;
            font-size: 21px;
          }

          &:hover {
            color: $blue;
          }
        }

        & .addcompare, & .addwishlist {
          background: rgba(255, 255, 255, 0.07);
          color: #fff;
          text-decoration: none;
          width: 44px;
          flex: 0 0 44px;
          height: 44px;
          border: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 21px;
          margin: 0 5px;
          border-radius: 50px;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.13),inset 0px 1px 0px 0px rgba(255, 255, 255, 0.14);

          i {
            font-weight: 400;
          }

          &:hover {
            background: rgba(255, 255, 255, 0);
          }
        }
      }

      &:before {
        transition: all .2s ease;
        position: absolute;
        left: -1px;
        top:0;
        content: '';
        background: #fff;
        width: 100%;
        opacity: 0;
        height: 100%;
      }

      &:hover:before {
        position: absolute;
        left: -1px;
        opacity: 1;
        top:0;
        content: '';
        background: rgba(67, 92, 218, 0.95);
        box-shadow: 0 0 29px rgba(77, 100, 220, 0.50);
        width: 100%;
        height: 100%;
      }

      &:hover {

        a.carousel__title__home {
          color: #fff;
        }

        .carousel__price {
          color: #fff;
        }

        .after__btn {
          z-index: 100;
          opacity: 1;
        }
      }

      & * {
        outline: none;
      }

      img {
        width: 212px;
        height: 212px;
        margin: 0 auto 25px;
        object-fit: contain;
      }
      a.carousel__title__home {
        font-size: 14px;
        color: #000;
        text-decoration: none;
        font-weight: 500;
        display: block;
        position: relative;
        height: 70px;
        overflow: hidden;

        &:hover {
          color: #fff;
        }
      }

      .carousel__price {
        color: $blue;
        font-weight: 800;
        font-size: 18px;
        margin-top: 30px;
        position: relative;
        transition: all .2s ease;
      }
    }
  }
}

@media (max-width: 1510px) {
  .carousel__product__home .carousel__home__block .slick-prev {
    left: 0;
  }
  .carousel__product__home .carousel__home__block .slick-next {
    right: 38px;
  }
}
@media (max-width: 1200px) {
  .carousel__product__home {
    flex-direction: column;
  }
  .carousel__product__home .carousel__block__info {
    width: 100%;
    padding: 30px;
    border-right:0;
  }
  .carousel__product__home .carousel__home__block {
    width: 100%;
    padding: 0;
  }
  .carousel__product__home:nth-child(2n) {
    flex-direction: column;
  }
  .carousel__product__home:nth-child(2n) .carousel__home__block {
    margin-left: 0;
  }
}
@media (max-width: 650px) {
  .carousel__product__home .carousel__home__block .carousel__home__item {
    border-right: 0;
    padding: 20px 15px;
  }

  .carousel__product__home .carousel__home__block .slick-dots li button:before {
    color: #737373;
  }
  .carousel__product__home .carousel__block__info {
    padding: 30px 15px;
  }
  .carousel__product__home .carousel__block__info p {
    padding: 0;
  }
}