.successful {
  padding: 150px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img.successful__icon {
    width: 160px;
  }

  .successful__title {
    font-size: 50px;
    font-weight: 300;
  }

  .successful__desc {
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
  }
  .successful__info {
    display: flex;
    padding: 32px 15px;
    width: 100%;
    margin: 60px 0;
    justify-content: center;
    border-top: 1px solid $borderc;
    border-bottom: 1px solid $borderc;

    .successful__info__block {
      display: flex;
      flex-direction: column;
      align-items: center;

      .form__title {
        margin-bottom: 5px;
      }

      span {
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        color: $blue;
      }
    }
  }
  .successful__btn {

    a {
      margin: 0 15px;
    }
  }
}

@media (max-width: 768px)  {
  .successful {
    padding: 40px 0;
  }
  .successful .successful__btn {
    display: flex;
    flex-direction: column;
  }
  .successful .successful__btn a {
    margin: 0 0 15px;
  }
  .successful .successful__info {
    padding: 0;
    margin: 30px 0;
  }
  .successful img.successful__icon {
    width: 130px;
  }
  .successful .successful__title {
    font-size: 40px;
    font-weight: 400;
  }
  .successful .successful__desc {
    font-size: 16px;
    font-weight: 500;
  }
}

@media (max-width: 550px)   {
  .successful .successful__info {
    flex-direction: column;
  }
  .successful .successful__info .successful__info__block {
    border-bottom: 1px solid $borderc;
    padding: 15px;

    &:last-child {
      border-bottom: 0;
    }
  }

  .successful img.successful__icon {
    width: 70px;
  }
  .successful .successful__title {
    font-size: 30px;
    font-weight: 500;
  }
  .successful .successful__desc {
    font-size: 12px;
    font-weight: 500;
  }
}