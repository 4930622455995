.catalog__block.favorite__block {

  .catalog__block__right {
    width: 100%;
    overflow: hidden;

    .catalog__block__product .catalog__product__item.grid {
      width: 25%;

      .delete__favorite {
        position: absolute;
        right: 15px;
        cursor: pointer;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $red;
          color: $white;
          text-decoration: none;
          border-radius: 100%;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

@media (max-width: 1250px)  {
  .favorite__block .catalog__block__right .catalog__block__product .catalog__product__item.grid {
    width: 33.333%;
  }
}

@media (max-width: 810px)  {
  .favorite__block .catalog__block__right .catalog__block__product .catalog__product__item.grid {
    width: 50%;
  }
}

@media (max-width: 550px)  {
  .favorite__block .catalog__block__right .catalog__block__product .catalog__product__item.grid {
    width: 100%;
  }
}