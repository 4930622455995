/* Desktop Main Menu Color */

.headerfull {
  background-color: #fff;
}

.wsmenu>.wsmenu-list {
  background-color: #fff;
}

.wsmenu>.wsmenu-list>li>.navtext>span {
  color: #9e9e9e;
}

.wsmenu>.wsmenu-list>li>.navtext>span+span {
  color: #555555;
}

.wsmenu>.wsmenu-list>li>.navtext>span+span:after {
  color: #9e9e9e
}

.wsmenu>.wsmenu-list>li.wsshopmyaccount>a {
  color: #828181;
}

.wsmenu>.wsmenu-list>.wsshopmyaccount>a i {
  color: #7b7b7b;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input {
  background-color: #eeeeee;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>.btnstyle {
  background-color: #eeeeee;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus {
  color: #000;
}

.wsmenu>.wsmenu-list>li>a {
  color: #fff;
}

.wsmenu>.wsmenu-list>li>a .wsarrow:after {
  border-top-color: #b3b3b3;
}

.wsmenu>.wsmenu-list>li>a:hover .wsarrow:after {
  border-top-color: #333;
}

.wsmenu>.wsmenu-list>li>a.active .wsarrow:after {
  border-top-color: #333;
}

.wsmenu>.wsmenu-list>li:hover>a .wsarrow:after {
  border-top-color: #333;
}

.wsmenu>.wsmenu-list>li>a>i {
  color: #898d91;
}

.wsmenu>.wsmenu-list>li>a.active i {
  color: #333;
}

.wsmenu>.wsmenu-list>li:hover>a>i {
  color: #333;
}

.wsmenu>.wsmenu-list>li>a:hover i {
  color: #333;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>.btnstyle:hover {
  background-color: #d7d7d7;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>.btnstyle:hover i {
  color: #333;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus~.btnstyle {
  background-color: #d7d7d7;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus~.btnstyle>i {
  color: #333;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus~.btnstyle i {
  color: #333;
}

.wsmenu>.wsmenu-list>li>a.active {
  background-color: #f5f5f5;
  color: #333;
}

.wsmenu>.wsmenu-list>li:hover>a {
  background-color: #f5f5f5;
  color: #333;
}

.wsmenu>.wsmenu-list>li>ul.sub-menu>li:hover>a {
  background-color: #f5f5f5;
  color: #333;
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>a {
  background-color: #f5f5f5;
  color: #333;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li.wsshoplink-active>a {
  background-color: #f5f5f5;
  color: #555555;
  border-left: 3px solid $blue;
}

/* Mobile Header Color */

.wsmobileheader {
  background-color: #fff;
}

.wsactive .wsmobileheader {
  border-left: solid 1px #e0e0e0;
}

.wsmenu>.wsmenu-list>li>.wsmenu-click {
  border-color: rgba(0, 0, 0, 0.1);
}

.wsmenu>.wsmenu-list>li>.wsmenu-click>i {
  color: rgba(0, 0, 0, 0.25);
}

.wsmenu>.wsmenu-list>li>a {
  border-bottom-color: rgba(0, 0, 0, 0.13);
}