.compare__block {
  display: flex;


  .height__block {
    height: 350px;
  }

  .compare__top {
    background: $wlight;
    height: 74px;
    margin-bottom: 50px;
    border-bottom: 1px solid $borderc;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $wgrey;
    font-size: 20px;

    i {
      font-weight: 500;
    }
  }

  .compare__feature {
    padding: 0 30px;

    .compare__feature__item {
      border-top: 1px dashed $borderc;
      padding: 20px 0;
      font-size: 12px;
      height: 61px;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: 600;
      display: flex;
      position: relative;
      align-items: flex-start;
      justify-content: center;

      span {
        overflow: hidden;
        height: 38px;
        text-align: center;
        position: absolute;
        background: #fff;

        &:before {
          content: '';
          background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
          position: absolute;
          bottom:1px;
          left: 0;
          width: 100%;
          height: 26px;
        }
      }

      &:hover {
        overflow: visible;

        span {
          z-index: 100;
          height: auto;

          &:before {
            display: none;
          }
        }
      }

      &.hide__distinction {
        display: none;
      }
    }
    .compare__feature__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;

      .submit__btn.small_btn {
        margin-top: 0;
      }
    }
  }

  .compare__left {
    width: 250px;
    flex: 0 0 250px;
    text-align: center;

    .compare__left__count {
      color: $blue;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      font-weight: 600;
      padding: 0 30px;
      align-items: center;

      span.count {
        font-size: 45px;
        display: block;
        line-height: 38px;
        margin-bottom: 5px;
      }

      span {
        line-height: 18px;
      }
      .compare__navigation {
        display: flex;
        height: 100%;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 14px;

        i {
          color: $wgrey;
          font-size: 30px;
        }

        .prev-compare {
          border-radius: 50px 0 0 50px;
          border: 1px solid $borderc;
          width: 50px;
          height: 50px;
          text-align: center;
          line-height: 60px;
          margin-right: -1px;
          transition: all .2s ease;
          cursor: pointer;

          &:hover {
            background: $blue;
            border-color: $blue;

            i {
              color: $white;
            }
          }
        }
        .next-compare {
          border-radius: 0 50px 50px 0;
          border: 1px solid $borderc;
          width: 50px;
          text-align: center;
          height: 50px;
          line-height: 60px;
          transition: all .2s ease;
          cursor: pointer;

          &:hover {
            background: $blue;
            border-color: $blue;

            i {
              color: $white;
            }
          }
        }
      }
      .only__distinction {
        font-weight: 500;
        margin-top: 20px;
        color: $wgrey;
        border-bottom: 1px dashed $borderc;
        font-size: 12px;
        cursor: pointer;
        transition: all .2s ease;

        &.active {
          color: $blue;
          border-color: $blue;
        }
      }
    }
  }

  .compare__slider__block {
    width: 100%;
    overflow: hidden;

    .compare__slider {

      .slick-track {
        margin-left: inherit;
      }

      .compare__slider__item {
        border-left: 1px solid $borderc;

        .button__in__cart {
          background: $blue;
          border: 1px solid $blue;
          color: $white;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 600;
          padding: 0 30px;
          display: flex;
          align-items: center;
          border-radius: 50px;
          outline: 0;
          height: 50px;
          cursor: pointer;
          box-shadow: 0 5px 19px rgba(77, 100, 220, 0.50);

          i {
            font-size: 22px;
            margin-right: 12px;
            font-weight: 300;
          }

          &:hover {
            border-color: $borderc;
            background: $white;
            color: $blue;
            box-shadow: 0 5px 19px rgba(77, 100, 220, 0);
          }
        }
      }

      * {
        outline: none;
      }

      .compare__feature__item {
        text-transform: inherit;
      }

      .compare__product {
        text-align: center;
        padding: 0 30px;

        a.compare__title {
          font-size: 12px;
          color: #000;
          text-decoration: none;
          height: 55px;
          overflow: hidden;
          display: block;
          margin-bottom: 30px;

          &:hover {
            color: $blue;
          }
        }

        .price {
          color: $blue;
          font-size: 18px;
          font-weight: 700;

          .oldprice {
            text-decoration: line-through;
            font-size: 14px;
            color: $wgrey;
          }
        }

        img {
          height: 140px;
          width: auto;
          margin: 30px auto 10px;
          object-fit: contain;
        }
        .product__rating__star {
          display: flex;
          font-size: 12px;
          flex-direction: column;

          .star__item {
            margin-right: 0;
            i {
              color: #ffb401;
            }
          }
          .rating__count {

            a {
              color: $wgrey;
              text-decoration: none;
              border-bottom: 1px dashed;
              display: inline-block;

              &:hover {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .compare__block .compare__left {
    width: 145px;
    flex: 0 0 145px;
    border-right: 1px solid #e7e7e7;
  }
  .compare__block .compare__slider__block .compare__slider .compare__slider__item {
    border-left: 0;
  }
  .compare__block .compare__left .compare__left__count {
    padding: 0 10px;
  }
  .compare__block .compare__feature {
    padding: 0 10px;
  }
  .compare__block .compare__slider__block .compare__slider .compare__product img {
    display: none;
  }
  .compare__block .compare__slider__block .compare__slider .compare__product .price {
    display: flex;
    flex-direction: column;
  }
  .compare__block .compare__slider__block .compare__slider .compare__product a.compare__title {
    margin-bottom: 15px;
  }
  .compare__block .compare__top {
    height: 50px;
    margin-bottom: 15px;
  }
  .compare__block .height__block {
    height: 200px;
  }
}