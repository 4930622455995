.nexthide {
  pointer-events: none;
  opacity: 0.3;
}
.cart__block {

  ul.tabs__cart {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $borderc;
    border-top: 1px solid $borderc;
    background: $wlight;

    &.tabs__cart__top {
      border-top: 0;
    }
    &.tabs__cart__top li {
      pointer-events: none;

      &.active a:before {
        font-family: "Font Awesome 5 Pro";
        font-weight:900;
        content: "\f254";
        font-size: 11px;
        margin-right: 10px;
        margin-top: 1px;
      }

      &.good a:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        content: "\f00c";
        font-size: 11px;
        margin-right: 10px;
        margin-top: 1px;
        color: $blue;
      }
    }

    li {
      flex: 1 1 100%;
      border-right: 1px solid $borderc;

      &:last-child {
        border-right: 0;
      }

      a {
        height: 74px;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        text-transform: uppercase;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        color: #000;

        i {
          color: #435cda;
          font-size: 18px;

          &.fa-angle-left {

            margin: 2px 10px 0 0;
          }

          &.fa-angle-right {
            margin: 2px 0 0 10px;
          }
        }
      }

      &.active {

        a {
          background: $blue;
          color: #fff;
        }
      }
    }
  }
  .tab_container {
    padding: 0;

    .cart__table {

      .cart__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $borderc;
        padding: 40px 280px;

        img.cart__img {
          width: 100px;
          height: 100px;
          flex: 0 0 100px;
          margin-right: 20px;
          object-fit: contain;
        }

        .cart__title__price {
          display: flex;
          align-items: center;

          .cart__title {
            flex: 0 0 320px;
            width: 320px;

            a {
              color: #000;
              text-decoration: none;

              &:hover {
                color: $blue;
              }
            }

            .property {
              text-transform: uppercase;
              font-size: 10px;
              font-weight: 700;
              margin-top: 10px;
            }
          }
          .cart__price {
            font-size: 15px;
            line-height: 18px;
            color: $wgrey;
            font-weight: 700;
            width: 190px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .oldprice {
              font-size: 12px;
              font-weight: 500;
              text-decoration: line-through;
            }
          }
        }

        .cart__coutn__endprice {
          display: flex;
          align-items: center;
          flex: 1 1 100%;

          .product__add__rg {
            flex: 0 0 50%;
          }

          .endprice {
            font-size: 15px;
            color: $blue;
            flex: 0 0 50%;
            font-weight: 700;
          }
        }

        .delete__cart {

          a {
            border-radius: 100%;
            color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            text-decoration: none;
            height: 40px;
            border:1px solid $borderc;

            &:hover {
              background: $red;
              border-color: $red;
              color: $white;
            }
          }
        }
      }
      .add__coupon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid $borderc;
        padding: 20px 280px 20px 280px;

        .submit__btn {
          font-size: 10px;
          font-weight: 400;
          height: 36px;
          line-height: 36px;
          padding: 0 20px;
          margin: 0;
          box-shadow: 0 4px 9px rgba(77,100,220,.5);

          &:hover {
            box-shadow: none;
          }
        }
        .cart__coupon__input input {
          height: 40px;
          width: 310px;
          outline: none;
          padding: 0 15px;
          border: 1px solid $borderc;
          margin: 0 45px;
        }
      }
      .cart__total__all {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid $borderc;
        padding: 20px 280px 20px 280px;
        font-weight: 700;
        font-size: 20px;

        .cart__total__price {
          color: $blue;
          margin-left: 50px;
        }
      }
    }
    .form__block {
      margin: 40px auto;
      padding: 0;
      box-shadow: none;

      .form__title {
        text-align: center;
      }
    }

    .cart__last__step {
      padding: 0;
      display: flex;

      .last__step__item {
        flex: 1 1 100%;
        padding: 20px 0 0;
        border-right: 1px solid $borderc;

        .form__title {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 15px;
          padding: 0 20px;
        }

        .radio__block {
          padding: 20px 0 0;

          .radio__item {
            border-bottom: 1px dashed $borderc;
            padding: 0 20px 10px 20px;
            margin-bottom: 10px;

            &:last-child {
              border-bottom: 0;
              margin-bottom: 0;
            }

            .payment-text p {
              color: $wgrey;
              font-size: 12px;
              padding: 0 0 0 25px;
              margin: 0;
            }

            & > label {
              display: flex;
              justify-content: space-between;

              span {
                color: $blue;
              }
            }

            .checkout-form-list  {

              .form__block {
                margin: 10px auto;
                position: relative;

                .form__block_dc {
                  border-radius: 0;
                }
                .city__form__c {
                  background: #fff;
                  width: 100%;
                  margin: 0;
                  max-height: 350px;
                  overflow: auto;
                  border: 1px solid #e7e7e7;
                  border-top: 0;
                  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
                  padding: 15px 0 0;
                  list-style: none;
                  z-index: 100;
                  position: absolute;
                  top: 58px;
                  left: 0;

                  li {
                    cursor: pointer;
                    padding: 0 15px 10px 15px;
                    border-bottom: 1px dashed $borderc;
                    margin-bottom: 10px;
                    font-size: 12px;

                    &:hover {
                      color: $blue;
                    }
                    &:last-child {
                      border-bottom: 0;
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }

        .order_details {
          padding: 20px 0 0;

          .order_details__item {
            border-bottom: 1px dashed $borderc;
            margin-bottom: 10px;
            padding: 0 20px 10px 20px;
            display: flex;
            justify-content: space-between;
            font-weight: 600;

            span {
              font-weight: 400;
              width: 50%;
            }

            &:last-child {
              border-bottom: 0;
              margin-bottom: 0;
              font-size: 20px;

              & span {
                color: $blue;
                font-weight: 600;
              }
            }
          }
        }

        &:last-child {
          background: $wlight;
        }
      }
    }

    & #tab3 {

      .cart__table .cart__item {
        padding: 20px 280px;
      }

      .cart__table .cart__item img.cart__img {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
      }
      .cart__table .cart__item .cart__title__price {
        font-size: 12px;
      }
      .cart__table .cart__item .cart__coutn__endprice {
        justify-content: center;
      }
      .cart__table .cart__item .cart__coutn__endprice .endprice {
        text-align: center;
      }
    }

    .end__order {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-top: 1px solid $borderc;

      .google__captcha {
        width: 100%;
        margin: 30px auto 0;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 30px;

        .form__block {
          margin: 10px auto;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .cart__block .tab_container .cart__table .cart__item, .cart__block .tab_container .cart__table .add__coupon, .cart__block .tab_container .cart__table .cart__total__all  {
    padding: 40px 80px;
  }
  .cart__block .tab_container #tab3 .cart__table .cart__item  {
    padding: 20px 80px;
  }
}

@media (max-width: 1200px) {
  .cart__block .tab_container .cart__table .cart__item, .cart__block .tab_container .cart__table .add__coupon, .cart__block .tab_container .cart__table .cart__total__all {
    padding: 40px 15px;
  }
  .cart__block .tab_container #tab3 .cart__table .cart__item {
    padding:20px 15px;
  }
}

@media (max-width: 991px) {
  .cart__block .tab_container .cart__table .cart__item .cart__title__price .cart__title {
    flex: 0 0 220px;
    width: 220px;
    font-size: 12px;
  }
  .cart__block .tab_container .cart__table .cart__item .cart__title__price .cart__price {
    width: 150px;
  }
  .cart__block .tab_container .cart__table .add__coupon {
    flex-wrap: wrap;
    justify-content: space-between;

    .cart__coupon__input {
      width: 82%;
      padding-right: 40px;
    }

    .cart__coupon__input input {
      margin: 0;
      width: 100%;
    }
  }
  .cart__block .tab_container .cart__last__step {
    flex-wrap: wrap;
  }
  .cart__block .tab_container .cart__last__step .last__step__item {
    flex: 0 0 100%;
    width: 100%;
    border-right:0;
    border-bottom: 1px solid #e7e7e7;

    &:last-child {
      border-bottom: 0;
    }
  }
}
@media (max-width: 768px)  {
  .cart__block .tab_container .cart__table .add__coupon {
    flex-direction: column;
  }
  .cart__block .tab_container .cart__table .add__coupon .cart__coupon__input {
    width: 100%;
    padding-right: 0;
    margin: 10px 0;
  }
  .cart__block .tab_container .cart__table .cart__total__all {
    justify-content: center;
  }
  .cart__block .tab_container .cart__table .cart__total__all .cart__total__price {
    color: #435cda;
    margin-left: 15px;
  }
  .cart__block .tab_container .form__block .form__block_dc {
    border-radius: 0;
    border: 0;
    padding: 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }
  .cart__block ul.tabs__cart.tabs__cart__top li a {
    height: 40px;
  }
  .cart__block ul.tabs__cart.tabs__cart__top li a span {
    display: none;
  }
  .cart__block ul.tabs__cart.tabs__cart__top li.active a:before, .cart__block ul.tabs__cart.tabs__cart__top li.good a:before {
    margin-right: 0;
  }

  .cart__block .tab_container .cart__table .cart__item {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  }
  .cart__block .tab_container .cart__table .cart__item .cart__coutn__endprice {
    margin-top: 40px;
  }
  .cart__block .tab_container .cart__table .cart__item .cart__title__price .cart__title .property {
    margin: 10px 0;
  }
  .cart__block .tab_container .cart__table .cart__item .cart__title__price .cart__title {
    flex: 0 0 auto;
    width: auto;
  }
  .cart__block .tab_container .cart__table .cart__item .cart__title__price .cart__price {
    width: auto;
    text-align: left;
  }
  
  .cart__block .tab_container .cart__table .cart__item .cart__title__price {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px 0 120px;
    position: absolute;
    top: 40px;
  }

  .cart__block .tab_container .cart__table .cart__item .delete__cart {
    position: absolute;
    top: 20px;
    right: 15px;

    a {
      background: $red;
      color: #fff;
      width: 20px;
      font-size: 10px;
      height: 20px;
      border-color: $red;
    }
  }
  .cart__block .tab_container .cart__table .cart__item .cart__coutn__endprice .product__add__rg .product__add__cart {
    height: 40px;
    margin-right: 14px;
  }

  .cart__block ul.tabs__cart li a {
    height: 50px;
    font-size: 10px;
  }
  .cart__block ul.tabs__cart li a i.fa-angle-left {
    margin: -2px 10px 0 0;
  }
  .cart__block ul.tabs__cart li a i.fa-angle-right {
    margin: -2px 0 0 10px;
  }

  .cart__block ul.tabs__cart {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
  }

  .cart__block ul.tabs__cart.tabs__cart__top {
    position: relative;
    bottom: auto;
  }


  .cart__block .tab_container #tab3 .cart__table {
    display: none;
  }

  .tab_content ul.tabs__cart {
    border-top: 1px solid #435cda;
    background: #435cda;
    border-bottom: 1px solid #435cda;

    li {
      border-right: 1px solid rgba(255, 255, 255, 0.06);
    }

    li a {
      color: #fff;

      i {
        color: #fff;
      }
    }
  }

  .myorders {

    .tab_container .cart__table .cart__item .cart__coutn__endprice {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%;
      width: 100%;
      padding: 0 0 0 120px;
    }
  }

}



@media (max-width: 550px)  {
  .cart__block .tab_container .cart__last__step .last__step__item .order_details .order_details__item {
    flex-direction: column;
  }
  .cart__block .tab_container .cart__last__step .last__step__item .order_details .order_details__item span {
    width: 100%;
    text-align: left;
  }
  .cart__block .tab_container .cart__last__step .last__step__item .order_details, .cart__block .tab_container .cart__last__step .last__step__item .radio__block {
    font-size: 12px;
  }
}