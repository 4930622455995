.brends__home {

  .brends__item {
    border-right: 1px solid $borderc;
    text-align: center;
    padding: 80px 0;
    outline: none;

    &:hover img {
      filter: grayscale(0);
      opacity: 1;
    }

    img {
      width: 174px;
      transition: all .2s ease;
      height: 110px;
      opacity: 0.8;
      margin: 0 auto;
      object-fit: contain;
      filter: grayscale(100%);
      filter: url(../img/grayscale.svg);
      filter: gray;
    }
  }
}

@media (max-width: 650px) {
  .brends__home .brends__item {
    border-right: 1px solid $borderc;
    text-align: center;
    padding: 40px 0;
  }
}

@media (max-width: 450px) {
  .brends__home .brends__item {
    border-right: 0;
  }
}