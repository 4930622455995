@import "../../variables";
.advantages {
  display: flex;
  justify-content: space-between;

  .advantages__items {
    width: 25%;
    padding:70px 30px;
    text-align: center;
    border-right: 1px solid $borderc;

    &:last-child {
      border-right: 0;
    }

    i {
      color: $blue;
      font-size: 48px;
    }
    .advantages__title {
      font-size: 16px;
      font-weight: 500;
      margin-top: 35px;
      text-transform: uppercase;
    }
    p {
      font-size: 12px;
      color: $wgrey;
      padding:0 30px;
    }
  }
}

@media (max-width: 1200px) {
  .advantages {
    flex-wrap: wrap;
  }
  .advantages .advantages__items {
    width: 50%;
    border-bottom: 1px solid $borderc;
  }
}

@media (max-width: 690px) {
  .advantages .advantages__items {
    width: 100%;
    border-right:  0;
    padding: 30px;
  }
}

@media (max-width: 450px) {
  .advantages .advantages__items {
    padding: 30px 15px;
  }
  .advantages .advantages__items i {
    font-size: 40px;
  }
  .advantages .advantages__items .advantages__title {
    margin-top: 15px;
  }
}