footer {
  background: $black;
  color: #fff;
  padding: 140px 0 20px 0;

  .footer__top {
    display: flex;
    justify-content: space-between;

    .footer__item {
      width: 20%;
      font-size: 12px;
      color: $wgrey;

      &:first-child {
        width: 40%;
        padding-right: 130px;
      }

      &.logo__footer img {
        width: auto;
        height: 36px;
        object-fit: contain;
      }

      .footer__title {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: $white;
        margin-bottom: 20px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          font-weight: 600;
          padding-bottom: 8px;

          a {
            color: $wgrey;
            text-decoration: none;

            &:hover {
              color: $blue;
            }
          }
        }
      }
    }
  }
  .footer__buttom {
    text-align: center;
    font-size: 12px;
    color: $wgrey;
    font-weight: 600;
    margin-top: 20px;
    border-top:1px solid rgba(255, 255, 255, 0.1);
    padding: 30px 0 10px 0;
  }
}


@media (max-width: 1200px) {
  footer .footer__top .footer__item:first-child {
    padding-right: 30px;
  }
}

@media (max-width: 991px) {
  footer .footer__top {
    flex-wrap: wrap;
  }
  footer .footer__top .footer__item:first-child {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
  footer .footer__top .footer__item {
    width: 31%;
    margin-bottom: 30px;
  }
}

@media (max-width: 650px) {
  footer .footer__top .footer__item {
    width: 100%;
  }
}