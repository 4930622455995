.banner {
  position: relative;
  background-size: cover;
  background-position: 50% 50%;

  &.blue:before {
    content: '';
    background: $blue;
    opacity: 0.95;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.red:before {
    content: '';
    background: $red;
    opacity: 0.95;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .banner_desc {
    text-transform: uppercase;
    padding: 30px 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 36px;
    font-weight: 500;
    color: $white;
    z-index: 100;
    position: relative;

    p {
      width: 70%;
      line-height: 70px;
      margin: 0 0 30px 0;
    }

    span.gifts__pr {
      font-size: 80px;
      font-weight: 600;
    }
  }
}


@media (max-width: 991px) {
  .banner .banner_desc {
    padding: 30px;
    font-size: 26px;
  }
  .banner .banner_desc span.gifts__pr {
    font-size: 70px;
  }
  .banner .banner_desc p {
    line-height: 60px;
  }
}
@media (max-width: 690px) {
  .banner .banner_desc {
    padding: 30px 15px;
    font-size: 22px;
  }
  .banner .banner_desc span.gifts__pr {
    font-size: 40px;
  }
  .banner .banner_desc p {
    line-height: 40px;
    width: 100%;
  }
}