.contact__block {

  .contact__maptop {
    height: 600px;
  }

  & .user__cab .form__lk, & .user__cab .orders__lk {
    width: 50%;
    padding: 50px 74px;
  }

  .user__cab .form__lk {
    border-right: 0;
    border-left: 1px solid #e7e7e7;
  }

  .contact__block__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;

    .contact__item {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px 20px;
      font-size: 12px;
      flex-direction: column;
      color: $wgrey;

      &:nth-child(1) {
        border-right: 1px dashed $borderc;
        border-bottom: 1px dashed $borderc;
      }

      &:nth-child(2) {
        border-bottom: 1px dashed $borderc;
      }

      &:nth-child(3) {
        border-right: 1px dashed $borderc;
      }

      i {
        font-size: 30px;
        color: $blue;
      }
      .contact__item__title {
        text-transform: uppercase;
        font-weight: 600;
        margin: 15px 0 5px 0;
        color: #000;
      }
    }
  }
}

@media (max-width: 991px) {
  .contact__block .user__cab .form__lk, .contact__block .user__cab .orders__lk {
    width: 100%;
    margin: 0 auto;
  }
  .contact__block .user__cab {
    flex-direction: column;
  }
  .contact__block .user__cab .form__lk {
    border-left: 0;
  }
  .contact__block .contact__maptop {
    height: 300px;
  }
}
@media (max-width: 768px) {
  .contact__block .user__cab .form__lk, .contact__block .user__cab .orders__lk {
    padding: 30px 15px;
  }
}
@media (max-width: 610px) {
  .contact__block .contact__block__info .contact__item {
    width: 100%;
    border-right: 0 !important;
    border-bottom: 1px dashed $borderc !important;
  }
  .contact__block .contact__block__info .contact__item:last-child {
    border-bottom: 0 !important;
  }
}
@media (max-width: 500px) {
  .contact__block .user__cab .form__lk {
    padding: 30px 0;
  }
}
