.page__block {
  min-height: 600px;
  padding: 100px 360px;
  line-height: 26px;

  .page__title {
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
    margin-bottom: 40px;
  }
}


@media (max-width: 1500px) {
  .page__block {
    padding: 50px 160px;
  }
}

@media (max-width: 991px) {
  .page__block {
    padding: 50px 15px;

    .page__title {
      font-size: 26px;
      margin-bottom: 30px;
      line-height: 33px;
    }
  }
}

@media (max-width: 610px) {
  .page__block {
    padding: 50px 15px;

    .page__title {
      font-size: 21px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
}