@import "../../variables";

.product__block {

  .product__top {
    padding: 110px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .product__top__left {
      width: 60%;
      position: relative;
      padding-right: 150px;

      .product__badge {
        position: absolute;
        top:0;
        right: 150px;
        background: #000;
        color: #fff;
        display: inline-block;
        padding: 3px 10px;
        font-size: 10px;
        font-weight: 600;
      }
      .product__category {
        display: flex;
        font-size: 12px;

        a {
          color: $blue;
          text-decoration: none;
          margin-left: 5px;
          border-bottom: 1px solid #fff;

          &:hover {
            border-bottom: 1px solid $blue;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
      .product__title {
        font-size: 24px;
        padding: 10px 0;
      }

      .product__rating__star {
        display: flex;
        font-size: 12px;

        .star__item {
          margin-right: 20px;
          i {
            color: #ffb401;
          }
        }
        .rating__count {

          a {
            color: $wgrey;
            text-decoration: none;
            border-bottom: 1px dashed;

            &:hover {
              color: $blue;
            }
          }
        }
      }

      .product__variant {
        margin: 15px 0;

        .variant__title {
          text-transform: uppercase;
          width: 100%;
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        form {
          display: flex;
          flex-wrap: wrap;
        }
      }
      .product__price__add__cart {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .product__price {
          font-size: 30px;
          font-weight: 600;

          .old__price {
            font-size: 20px;
            color: #b9b9b9;
          }
        }
      }
      .whishlist__compare__share {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $borderc;
        width: 100%;
        margin-top: 40px;
        padding: 20px 0;
      }

      .add__whishlist__compare {

        button {
          border: 0;
          outline: none;
          background: #fff;
          font-size: 12px;
          color: $wgrey;
          cursor: pointer;
          margin-right: 20px;

          i {
            font-weight: 400;
            font-size: 14px;
            margin-right: 10px;
          }

          &:hover {
            color: $blue;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .product__top__right {
      width: 40%;
      display: flex;
      justify-content: space-between;

      .product__slider {
        width: 350px;
        height: 350px;
        flex: 0 0 350px;

        .slick-slide {
          outline: none;
        }
        .slick-slide a {
          width: 100%;
          height: 350px;
          display: block;
          outline: none;
          text-align: center;

          &:hover {
            opacity: 0.8;
          }

          img {
            width: 100%;
            outline: none;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .product__slider__nav {

        .slick-list {
          padding: 1px 0;
          width: 80px;
        }

        .slick-slide {
          width: 80px !important;
          height: 80px;
          cursor: pointer;
          border: 1px solid $borderc;
          margin-bottom: -1px;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transition: all .2s ease;

          &:hover {
            opacity: 0.7;
          }

          &.slick-current:after {
            content: '';
            background: $blue;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            width: 100%;
            box-shadow: 0 5px 8px rgba(77, 100, 220, 0.5);
          }

          &.slick-current:hover {
            opacity: 1;

          }
        }

        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
      }
    }
  }

  .product__bottom {

    .tabs__product {
      display: flex;
      padding: 0 110px;
      margin: 0;
      list-style: none;
      border-top: 1px solid $borderc;
      border-bottom: 1px solid $borderc;
      font-size: 12px;
      text-transform: uppercase;

      li {
        position: relative;
        margin-right: 74px;
        transition: all .2s ease;

        a {
          text-decoration: none;
          padding: 32px 0 30px;
          display: block;
          color: $wgrey;
          font-weight: 500;
        }

        &.active:after {
          content: '';
          background: $blue;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 100%;
          box-shadow: 0 5px 8px rgba(77, 100, 220, 0.5);
        }
        &.active {

          a {
            color: $blue;
          }
        }
      }

    }
    .product__desc__block {
      background: $wlight;
      padding: 0 0 0 110px;
      display: flex;
      justify-content: space-between;

      .tab_container {
        width: 100%;
        padding: 56px 80px 56px 0;

        .reviews__title {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 30px;
        }

        .reviews__block {

          .reviews__item {
            padding: 30px 0;
            border-bottom: 1px solid $borderc;
            font-size: 12px;
            width: 100%;

            &:first-child {
              border-top: 1px solid $borderc;
            }

            .reviews__item__top {
              color: #000000;
              font-weight: 600;

              span {
                color: $wgrey;
                margin-left: 10px;
              }
            }
            .reviews__item__desc {
              padding-top: 5px;
            }
          }
        }
      }

      .product__desc__right {
        width: 368px;
        flex: 0 0 368px;
        border-left: 1px solid $borderc;

        .desc__right__item {
          display: flex;
          padding: 30px;
          border-bottom: 1px solid $borderc;

          i {
            font-size: 38px;
            margin-top: 7px;
            color: $blue;
            width: 48px;
            flex: 0 0 48px;
            margin-right: 30px;
          }

          .right__item__t {
            font-size: 12px;
            color: $wgrey;

            .right__item__title {
              font-weight: 500;
              color: #000;
              font-size: 14px;
              text-transform: uppercase;
            }
            ul.list__st {
              color: $wgrey;
              padding-top: 10px;
              padding-bottom: 0;
            }
            p {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .carousel__product__home {
    border-bottom: 0;
    border-top: 1px solid $borderc;
    margin-top: -1px;
  }
}


@media (max-width: 1370px) {
  .product__block .product__top .product__top__left {
    padding-right: 30px;
  }
}
@media (max-width: 1200px) {
  .product__block .product__top .product__top__left {
    padding-right: 5px;
  }
}
@media (max-width: 1100px) {
  .product__block .product__top .product__top__left {
    width: 100%;
    padding-right: 0;
  }
  .product__block .product__top {
    flex-direction: column-reverse;
  }
  .product__block .product__top .product__top__right {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    border-bottom: 1px solid $borderc;
    padding-bottom: 20px;
  }
  .product__block .product__top {
    padding: 50px;
  }
  .product__block .product__bottom .tabs__product {
    padding: 0 50px;
  }
  .product__block .product__bottom .product__desc__block {
    padding: 0 50px;
    flex-direction: column;
  }
  .product__block .product__bottom .product__desc__block .tab_container {
    width: 100%;
    padding: 56px 0;
  }
  .product__block .product__bottom .product__desc__block .product__desc__right {
    width: 100%;
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
  }
  .product__block .product__top .product__top__left .product__badge {
    top: 0;
    right: 0;
  }
  .product__block .product__bottom .product__desc__block .product__desc__right .desc__right__item {
    width: 50%;
    border: 1px solid $borderc;
    margin-top: -1px;
    margin-left: -1px;
    height: 175px;
    align-items: center;
  }

  .product__block .product__top .product__top__right .product__slider {
    width: 250px;
    height: 250px;
    flex: 0 0 250px;
  }
  .product__block .product__top .product__top__right .product__slider .slick-slide a {
    height: 250px;
  }
  .product__block .product__top .product__top__right .product__slider__nav img {
    width: 40px;
    height: 40px;
  }
  .product__block .product__top .product__top__right .product__slider__nav .slick-slide {
    width: 60px!important;
    height: 60px;
  }
  .product__block .product__top .product__top__left .product__title {
    font-size: 18px;
    padding: 0 0 10px 0;
    font-weight: 500;
  }
  .product__block .product__bottom .tabs__product li.active:after, .product__block .product__top .product__top__right .product__slider__nav .slick-slide.slick-current:after {
    box-shadow: 0 1px 5px rgba(77,100,220,.5);
  }
}

@media (max-width: 768px) {
  .product__block .product__bottom .product__desc__block .product__desc__right .desc__right__item {
    width: 100%;
    border: 0;
    border-top: 1px solid $borderc;
    border-bottom: 1px solid $borderc;
    margin-top: -1px;
    margin-left: 0;
    height: auto;
  }
  .product__block .product__bottom .product__desc__block .product__desc__right {
    border-left: 0;
  }
  .product__block .product__top {
    padding: 50px 15px;
  }
  .product__block .product__bottom .tabs__product, .product__block .product__bottom .product__desc__block {
    padding: 0 15px;
  }
  .product__block .product__bottom .tabs__product {
    justify-content: center;
  }
  .product__block .product__bottom .tabs__product li {
    margin: 0 25px;
  }
}

@media (max-width: 570px) {
  .product__block .product__top .product__top__left .product__price__add__cart {
    flex-direction: column;
    align-items: baseline;
  }
  .product__block .product__top .product__top__left .product__price__add__cart .product__price {
    margin: 5px 0 20px 0;
  }
  .product__block .product__top .product__top__left .whishlist__compare__share {
    flex-direction: column;
    align-items: center;
  }
  .product__block .product__top .product__top__left .whishlist__compare__share .add__whishlist__compare {
    margin-bottom: 15px;
  }
}

@media (max-width: 450px) {
  .product__block .product__top .product__top__left .product__price__add__cart .product__add__rg .button__in__cart {
    font-size: 12px;
    padding: 0 20px;
  }
  .product__block .product__top .product__top__left .product__price__add__cart .product__add__rg .button__in__cart i {
    font-size: 16px;
  }
  .product__block .product__top .product__top__left .product__title {
    padding: 10px 0;
  }
  .product__block .product__bottom .tabs__product li {
    margin: 0;
  }
  .product__block .product__bottom .tabs__product {
    justify-content: space-between;
  }
  .product__block .product__bottom .tabs__product li a {
    padding: 26px 0 24px;
  }
  .product__block .product__bottom .product__desc__block .product__desc__right .desc__right__item {
    padding: 15px;
    flex-direction: column;
    align-items: baseline;
  }
}