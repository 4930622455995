.user__cab {
  display: flex;
  padding: 0;
  flex-wrap: wrap;

  .form__lk {
    width: 50%;
    border-right: 1px solid $borderc;
    padding: 100px 74px;

    .form__block {
      box-shadow: none;
      max-width: 500px;
      overflow: visible;

      .form__block_dc {
        margin: 0;

        .submit__btn {
          margin-top: 0;
          border-top:0;
        }
      }

      .outlog {
        background: $red;
        color: #fff;
        border: 1px solid $red;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 0 30px;
        margin: 20px auto 0;
        width: 110px;
        text-align: center;
        line-height: 35px;
        display: block;
        border-radius: 50px;
        text-decoration: none;
        height: 35px;
        box-shadow: 0 2px 5px rgba(218,67,67,.5);

        &:hover {
          background: $white;
          color: $red;
          border-color: $borderc;
          box-shadow: 0 2px 5px rgba(218,67,67,0);
        }
      }
    }
    .form__block .form__title {
      text-align: center;
      width: 100%;
      background: $white;
      height: 50px;
      line-height: 50px;
      position: relative;
      border-bottom: 0;
      overflow: hidden;

      &:after {
        display: none;
      }
    }
  }
  .orders__lk {
    width: 50%;
    padding: 100px 74px;

    .orders__lk__block {
      display: flex;
      flex-direction: column;

      .order__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        border-bottom: 1px solid $borderc;
        padding-bottom: 10px;
        margin-bottom: 10px;
        text-align: center;

        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }

        .submit__btn.small_btn {
          border-radius: 50px;
          height: 35px;
          line-height: 35px;
          margin: 0;
        }

        .order__number {
          width: 90px;
          flex: 0 0 90px;
          text-align: left;
        }
      }
    }
  }
  .form__title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
}

@media (max-width: 1300px) {
  .user__cab .form__lk, .user__cab .orders__lk {
    padding: 50px 30px;
  }
}

@media (max-width: 991px) {
  .user__cab {
    flex-direction: column-reverse;
  }
  .user__cab .form__lk, .user__cab .orders__lk {
    width: 100%;
    padding: 30px 15px;
  }
  .user__cab .orders__lk {
    border-bottom: 1px solid #e7e7e7;
  }
  .user__cab .form__lk {
    border-right: 0;
  }
}
@media (max-width: 550px) {
  .user__cab .orders__lk .orders__lk__block {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .order__item {
      width: 100%;
      background: #f7f7f7;
      flex-direction: column;
      border: 1px solid $borderc;
      padding: 15px 0 0;
      text-align: left;
      align-items: flex-start;

      & > div {
        border-bottom: 1px solid $borderc;
        padding: 0 15px 10px 15px;
        margin-bottom: 10px;
        width: 100%;

        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
      }

      .order__number {
        width: 100%;
        flex: 0 0 auto;
        text-align: left;
      }
    }
  }
}

@media (max-width: 500px) {
  .user__cab .form__lk, .user__cab .orders__lk {
    padding:30px 0;
  }
  .user__cab .orders__lk .orders__lk__block .order__item {
    border-left: 0;
    border-right: 0;
    margin-bottom: 25px;
  }
}